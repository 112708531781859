import React, { useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'

// Images
import Veilig from 'img/icon/CM-veilig_werken.inline.svg'
import Productief from 'img/icon/CM-productief_werken.inline.svg'
import Overal from 'img/icon/CM-overal_werken.inline.svg'
import Flexibel from 'img/icon/CM-flexibel_werken.inline.svg'
import Beheer from 'img/icon/CM-beheer.inline.svg'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledCustomLink = styled(CustomLink)`
  &:hover {
    text-decoration: none;
  }
`

const StyledSolutions = styled.div``

const Solution = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  height: 300px;
  width: 100%;
  border-radius: 20px;

  h2 {
    font-size: ${(props) => props.theme.font.size.ms};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 20px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.s};
    text-align: center;
    padding: 0 20px;
    margin-bottom: 0;
  }
`

const SolutionRow = styled.div`
  margin-left: -80px;
  margin-right: -80px;

  @media screen and (max-width: 1319px) {
    margin-left: -40px;
    margin-right: -40px;
  }

  @media screen and (max-width: 1039px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @media screen and (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const IconContainer = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`

const SolutionShadow = styled(motion.div)`
  position: absolute;
  background: ${(props) => props.theme.color.gradient.third};
  z-index: 1;
  border-radius: 23px;
`

const SolutionInner = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  position: relative;
  z-index: 2;
  border-radius: 20px;
`

const Button = styled.a`
  color: ${(props) => props.theme.color.text.light};
  font-weight: ${(props) => props.theme.font.weight.xl};
  position: relative;
  background: ${(props) => props.theme.color.gradient.main};
  border-radius: 29.5px;
  display: inline-block !important;
  color: ${(props) => props.theme.color.text.light} !important;
  font-weight: ${(props) => props.theme.font.weight.xl} !important;
  padding: 10px 50px;
  height: 100%;
  display: block;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.color.text.light};
  }
`

const CustomerPortal = ({ className }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  const {
    customerportal: { edges: customerportal },
  } = useStaticQuery(graphql`
    {
      customerportal: allWordpressWpKlantportaal(
        sort: { fields: date, order: ASC }
      ) {
        edges {
          node {
            title
            slug
            wordpress_id
            path
            acf {
              texts {
                link
                small_description
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledSolutions
      ref={ref}
      className={`container ${className ? `${className}` : ``}`}
    >
      <SolutionRow className="row py-lg-5 justify-content-center justify-content-lg-between">
        {customerportal.map(({ node: solution }, index) => (
          <StyledCustomLink
            className="px-lg-0 px-md-3 py-lg-0 py-3 col-sm-12 col-md-6 col-lg-2"
            to={solution.acf.texts.link}
            key={solution.wordpress_id}
            external
            newPage
          >
            <Solution
              initial="rest"
              whileHover="hover"
              whileTap="stress"
              animate={isVisible || view ? 'init' : 'rest'}
              variants={{
                init: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: 'spring',
                    duration: 0.5,
                    delay: index * 0.1,
                  },
                },
                stress: {
                  scale: 0.95,
                },
                rest: {
                  opacity: 0.3,
                  y: 50,
                  scale: 1,
                },
                hover: {
                  scale: 1.05,
                },
              }}
            >
              <SolutionInner className="h-100 p-lg-0 p-5 d-flex align-items-center justify-content-center">
                <div>
                  <IconContainer className="d-flex justify-content-center pb-3">
                    {
                      {
                        contact: <Overal />,
                        'kpn-factuurbeheer': <Flexibel />,
                        statusdashboard: <Productief />,
                        'kpn-beheersysteem': <Beheer />,
                      }[solution.slug]
                    }
                  </IconContainer>
                  <h2 className="text-center">{solution.title}</h2>
                  {parse(solution.acf.texts.small_description)}
                  <div className="d-flex mt-4">
                    <Button
                      href={solution.acf.texts.link}
                      className="mx-auto"
                      target="_blank"
                    >
                      Bezoek
                    </Button>
                  </div>
                </div>
              </SolutionInner>
              <SolutionShadow
                variants={{
                  rest: {
                    zIndex: -1,
                    opacity: 0,
                    height: 'calc(100% - 4px)',
                    width: 'calc(100% - 4px)',
                    left: '2px',
                    top: '2px',
                  },
                  hover: {
                    opacity: 1,
                    height: 'calc(100% + 8px)',
                    width: 'calc(100% + 8px)',
                    left: '-4px',
                    top: '-4px',
                  },
                }}
              />
            </Solution>
          </StyledCustomLink>
        ))}
      </SolutionRow>
    </StyledSolutions>
  )
}

export default CustomerPortal
